import { SnackbarProvider } from 'notistack';
import MainCard from 'ui-component/cards/MainCard';
import SchedulesReport from './components/SchedulesReport';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';

const Report = () => {
    return (
        <MainCard title="Relatórios" icon={<AssessmentOutlinedIcon color="primary" />}>
            <SnackbarProvider />
            <SchedulesReport />
        </MainCard>
    );
};

export default Report;
